import React, { useEffect, useState } from "react";
import MarkdownText from "../common/MarkdownText";
import { HotelCard, Message, MessageReaction } from "../../models/ChatModel";
import HotelCardsCarousel from "../hotel/HotelCardsCarousel";
import { ReactComponent as Thumb } from "../../images/thumb.svg";
import { ReactComponent as ThumbPressed } from "../../images/thumb_pressed.svg";
import BouncingBubblesLoading from "../common/BouncingBubblesLoading";
import HotelCardsSmartCarousel from "../hotel/HotelCardsSmartCarousel";
import HotelLink from "../hotel/HotelLink";

interface ChatContentProps {
    desktopRes: boolean;
    messages: Message[];
    clientId: string;
    loading: boolean;
    handleFeedback: (feedback: MessageReaction, msg: Message) => void;
    messagesEndRef: React.MutableRefObject<null | HTMLDivElement>;
}

interface CardState {
    messageIndex: number;
    collapsed: boolean;
}

const ChatContent: React.FC<ChatContentProps> = ({
    desktopRes,
    messages,
    clientId,
    loading,
    handleFeedback,
    messagesEndRef,
}) => {
    const [cardsState, setCardsState] = useState<CardState[]>([]);

    const isCollapsed = (index: number): boolean => {
        return (
            cardsState.find((c) => c.messageIndex === index)?.collapsed ?? true
        );
    };

    const handleExpand = (index: number) => {
        setCardsState((prevState) =>
            prevState.map((card) =>
                card.messageIndex === index
                    ? { ...card, collapsed: false }
                    : { ...card, collapsed: true }
            )
        );
    };

    useEffect(() => {
        const newCardsState = messages.reduce<CardState[]>(
            (acc, msg, index) => {
                if (Array.isArray(msg.content)) {
                    acc.push({
                        messageIndex: index,
                        collapsed: acc.length > 0,
                    });
                }
                return acc;
            },
            []
        );

        setCardsState(newCardsState);
    }, [messages]);

    const formatText = (text: string): string => text
        .replace(/\n/g, "\n\n")

    return (
        <div
            className={`flex-grow overflow-y-auto overflow-x-visible mb-4 pr-4 pt-12 ${desktopRes ? "overflow-hide" : ""
                }`}
            style={{
                overflowAnchor: "none",
            }}
        >
            {desktopRes && (
                <div className="fixed w-[32dvw] shadow-after top-[calc(0rem)] right-0 z-10" />
            )}
            {messages.map((msg, index) => (
                <div
                    key={index}
                    className={`mb-4 ${msg.role === "user"
                        ? "text-right user-message float-right"
                        : "text-left"
                        }`}
                >
                    {msg.role === "assistant" && Array.isArray(msg.content) ? (
                        <>
                            {!desktopRes && (
                                <HotelCardsCarousel
                                    clientId={clientId}
                                    cards={msg.content as HotelCard[]}
                                />
                            )}
                            {desktopRes && (
                                <HotelCardsSmartCarousel
                                    clientId={clientId}
                                    cards={msg.content as HotelCard[]}
                                    collapsed={isCollapsed(index)}
                                    onExpand={() => handleExpand(index)}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <div
                                className={`inline-block p-3 rounded-lg ${msg.role === "user"
                                    ? "bg-[#F1F1F1]"
                                    : "bg-transparent"
                                    }`}
                            >
                                {msg.role === "assistant" ? (
                                    <MarkdownText>
                                        {formatText(msg.content as string)}
                                    </MarkdownText>
                                ) : (
                                    (msg.content as string)
                                )}
                            </div>
                            {msg.role === "assistant" && msg.buttonLink ? (
                                <HotelLink
                                    clientId={""}
                                    url={""}
                                    backLink={msg.buttonLink}
                                    customText="Открыть поиск"
                                />
                            ) : (
                                ""
                            )}
                        </>
                    )}
                    {msg.role !== "user" &&
                        isCollapsed(index) &&
                        (index !== messages.length - 1 || !loading) && (
                            <>
                                <br />
                                <button
                                    className="p-2"
                                    onClick={() =>
                                        handleFeedback("DISLIKE", msg)
                                    }
                                >
                                    {msg.feedback === "DISLIKE" ? (
                                        <ThumbPressed className="rotate-180 -scale-x-100 scale-y-100" />
                                    ) : (
                                        <Thumb className="rotate-180 -scale-x-100 scale-y-100" />
                                    )}
                                </button>
                                <button
                                    className="p-2"
                                    onClick={() => handleFeedback("LIKE", msg)}
                                >
                                    {msg.feedback === "LIKE" ? (
                                        <ThumbPressed fill="black" />
                                    ) : (
                                        <Thumb fill="black" />
                                    )}
                                </button>
                            </>
                        )}
                </div>
            ))}
            <BouncingBubblesLoading loading={loading} />
            <div
                className="h-[10dvh] xl:h-[15dvh] w-full"
                ref={messagesEndRef}
                style={{ float: "left", clear: "both", content: " " }}
            />
        </div>
    );
};

export default ChatContent;
