import React, { useState, useCallback, useRef, useEffect } from "react";
import { HotelCard } from "../../models/ChatModel";
import HotelLink from "./HotelLink";
import { CaretLeftIcon, CaretRightIcon } from "@radix-ui/react-icons";
import { motion, AnimatePresence } from "framer-motion";
import {
    capitalizeFirstLetter,
    formatDate,
    formatPriceNumber,
    getChildrensText,
    getUrl,
} from "../../utils/utils";

interface HotelCardsSmartCarouselProps {
    cards: HotelCard[];
    clientId: string;
    collapsed?: boolean;
    onExpand?: () => void;
}

const HotelCardsSmartCarousel: React.FC<HotelCardsSmartCarouselProps> = ({
    cards,
    clientId,
    collapsed: initialCollapsed = false,
    onExpand,
}) => {
    const [collapsed, setCollapsed] = useState(initialCollapsed);
    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    const handleExpand = useCallback(() => {
        setCollapsed(false);
        onExpand?.();
    }, [onExpand]);

    const handlePrev = useCallback(() => {
        setCurrentIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : cards.length - 1
        );
    }, [cards.length]);

    const handleNext = useCallback(() => {
        setCurrentIndex((prevIndex) =>
            prevIndex < cards.length - 1 ? prevIndex + 1 : 0
        );
    }, [cards.length]);

    useEffect(() => {
        setCollapsed(initialCollapsed);
    }, [initialCollapsed]);

    const showButtons = () => cards.length > 3;

    return (
        <AnimatePresence>
            {collapsed ? (
                <motion.div
                    initial={{ opacity: 0, height: 1 }}
                    animate={{ opacity: 1, height: "100%" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="relative w-full h-full p-4 rounded-xl border border-[#E5E5E5] pl-[30px] box-sizing"
                >
                    <div className="absolute w-1.5 h-5/6 rounded-2xl bg-[#FFB534] top-2.5 left-2.5" />
                    <p>
                        {capitalizeFirstLetter(cards[0].hotel?.region)},{" "}
                        {capitalizeFirstLetter(cards[0].hotel?.country)}
                    </p>
                    <p>
                        {cards[0].ticketInclude ? "Перелёт + отель" : "Отель"} ·{" "}
                        {formatDate(cards[0].startDate)} · {cards[0].duration}{" "}
                        ночей · {cards[0].offerData?.adults ?? "Нет данных"}
                        {getChildrensText(cards[0].offerData?.kids) === " без детей"
                            ? ""
                            : `, ${getChildrensText(cards[0].offerData?.kids)}`}
                    </p>
                    <div
                        className="text-[#5B66F7] cursor-pointer"
                        onClick={handleExpand}
                    >
                        Посмотреть варианты
                    </div>
                </motion.div>
            ) : (
                <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "100dvh" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="fixed left-10 bottom-0 w-[calc(69dvw-90px)] z-20 bg-transparent flex flex-row items-center select-none h-screen pt-[60dvh] overflow-x-hidden"
                    ref={containerRef}
                >
                    {showButtons() && (
                        <div
                            className="size-8 p-2 bg-[rgba(255,255,255,0.9)] bg-cover place-content-center rounded-lg cursor-pointer relative bottom-32 z-10"
                            onClick={handlePrev}
                        >
                            <div className="mx-auto size-4">
                                <CaretLeftIcon />
                            </div>
                        </div>
                    )}
                    <div className="flex items-stretch relative">
                        {cards.map((card, index) => (
                            <motion.div
                                key={card.tourId}
                                className="flex-none w-[17dvw] absolute -bottom-[6rem]"
                                initial={{ opacity: 0, x: "100%" }}
                                animate={{
                                    opacity:
                                        index >= currentIndex &&
                                        index < currentIndex + 3
                                            ? 1
                                            : 0,
                                    x: `${(index - currentIndex) * 100}%`,
                                }}
                                transition={{
                                    duration: 0.3,
                                    ease: "easeInOut",
                                }}
                                style={{
                                    left: "3dvw",
                                    right: 0,
                                    // zIndex: index === currentIndex + 1 ? 1 : 0,
                                }}
                            >
                                <div className="bg-white rounded-3xl shadow-md p-4 m-2 flex flex-col justify-between 2xl:h-[28rem] xl:h-[24rem]">
                                    <div>
                                        <img
                                            src={card.hotel?.mainPhoto}
                                            alt={card.hotel?.name}
                                            className="w-full 2xl:h-40 xl:h-[128px] object-cover rounded-2xl mb-2"
                                        />
                                        <h3 className="2xl:text-lg xl:text-base font-extrabold text-[#224824] line-clamp-3">
                                            {card.hotel?.name}{" "}
                                            {card.hotel?.category}
                                        </h3>
                                        <div className="grow" />
                                    </div>
                                    <div>
                                        <p className="2xl:text-sm xl:text-xs text-[#224824]">
                                            Взрослых:{" "}
                                            {card.offerData?.adults ?? "Нет данных"},
                                            {getChildrensText(card.offerData?.kids)}
                                        </p>
                                        <p className="2xl:text-sm xl:text-xs text-[#224824]">
                                            Дата заезда:{" "}
                                            {formatDate(card.startDate) ??
                                                "Нет данных"}
                                        </p>
                                        <p className="2xl:text-sm xl:text-xs text-[#224824]">
                                            Дней в отеле: {card.duration}
                                        </p>
                                        <p className="2xl:text-lg xl:text-base font-extrabold mt-2 text-[#224824]">
                                            от <span className="ml-2" />{" "}
                                            {formatPriceNumber(card.price)} руб.
                                        </p>
                                        <HotelLink
                                            clientId={clientId}
                                            tourId={card.tourId}
                                            url={`${getUrl()}/`}
                                            offerData={card.offerData}
                                        />
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                    {showButtons() && (
                        <div
                            className="size-8 p-2 bg-[rgba(255,255,255,0.9)] bg-cover place-content-center rounded-lg cursor-pointer relative left-[calc(17dvw*3+6dvw)] bottom-32"
                            onClick={handleNext}
                        >
                            <div className="mx-auto size-4">
                                <CaretRightIcon />
                            </div>
                        </div>
                    )}
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default HotelCardsSmartCarousel;
