export type MessageRole = "client" | "assistant" | "user" | "system";
export type ScenarioStep = 1 | 2 | 3;
export type MessageReaction = "LIKE" | "DISLIKE";

export interface Message {
    id?: string;
    role: MessageRole;
    content: string | HotelCard[];
    loading?: boolean;
    isEnd?: boolean;
    name?: string;
    feedback?: MessageReaction;
    buttonLink?: string;
}

export interface ResponseChatModel {
    text: string;
    id?: string;
    isEnd?: boolean;
    link?: string;
}

export interface Hotel {
    mainPhoto?: string;
    name?: string;
    url?: string;
    country?: string;
    region?: string;
    popularity?: number;
    adultsPopularity?: number;
    familyPopularity?: number;
    singleAdultPopularity?: number;
    category?: string;
    latitude?: number;
    longitude?: number;
    email?: string;
    hasBeachData?: boolean;
    roomCount?: number;
    floorCount?: number;
    description?: string;
    rating?: number;
    centerDistance?: number;
    airportDistance?: number;
    beachLine?: number;
    features?: any;
}

export interface ResponseHotelModel {
    id?: string;
    tours?: HotelCard[];
    message?: string;
    offerData?: OfferData;
}

export interface OfferData {
    departureCity?: string;
    adults?: number | string;
    kids?: number | string;
    kidsAges?: number[];
    startDateFrom?: string;
    startDateTo?: string;
    durationFrom?: number | string;
    durationTo?: number | string;
}

export interface HotelCard {
    hotel?: Hotel;
    price?: number;
    provider?: string;
    ticketInclude?: boolean;
    duration?: number;
    tourId: string;
    guests?: number | string;
    checkInDate?: number | string;
    startDate?: string;
    offerData?: OfferData;
}

export interface HotelCardsMessage extends Message {
    content: HotelCard[];
    tours?: HotelCard[];
}

export interface ResponseWithLinkModel {
    id?: string;
    link: string;
    message?: string;
}

export const initMessages = (): Message[] => {
    return [
        {
            role: "assistant",
            content:
                "👋 Я здесь, чтобы создать вместе с вами путешествие мечты! 🏝",
        },
        {
            role: "assistant",
            content:
                "😉 Напишите, куда вы мечтаете отправиться в своем следующем путешествии?",
        },
    ];
};
